import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import config from "../../config"

const Header = ({ siteTitle }) => ( 

  <header
    style={{
      background: `${config.primaryColor}`
    }}
  > 

  <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css" rel="stylesheet" type="text/css" />
  <link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600&display=swap" rel="stylesheet" />

  <Container>  
    <SocialList> 
      <li><a href={`${config.linkedin}`} target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
      <li><a href={`${config.twitter}`} target="_blank"><i className="fab fa-twitter"></i></a></li>
      <li><a href={`${config.github}`} target="_blank"><i className="fab fa-github-alt"></i></a></li>
      <li><a href={`${config.drupal}`} target="_blank"><i className="fab fa-drupal"></i></a></li>
      <li><a href={`${config.email}`} target="_blank"><i className="fas fa-at"></i></a></li>

    </SocialList>



    <MainMenu>
      <li><Link to="/" activeClassName="active">About</Link></li>
      <li><Link to="/photos" activeClassName="active">Photos</Link></li>
      <li><Link to="/projects" activeClassName="active">Projects</Link></li>
      <li><Link to="/blog" activeClassName="active">Blog</Link></li>
    </MainMenu>

  </Container>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header


const Container = styled.div`
  display: flex;
  padding: 10px 20px;
  flex-direction: column;

  @media(min-width: 600px) {
    flex-direction: row;
  }
`

const SocialList = styled.ul`
  margin: 0 auto;
  display: block;
  list-style: none;
  display: flex;
  font-size: 20px;
  align-items: center;

  @media(min-width: 768px) {
    margin: 0;
  }

  li {
    margin: 0 15px;

    a {
      color: #fff;

      &:hover {
        color: #2a8fdf;
      }
    }
  }
`

const MainMenu = styled.ul`
  margin: 20px auto 0 auto;
  list-style: none;
  display: flex;
  font-size: 18px;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media(min-width: 768px) {
    margin: 0;
    font-size: 20px;
    justify-content: flex-end;
  }

  li {
    text-transform: uppercase;
    margin: 0 15px;

    a {
      color: #fff;
      text-decoration: none;

      &:hover,
      &.active {
        color: #2a8fdf;
        border-bottom: 2px solid #2a8fdf;
        padding-bottom: 9px;
      }
    }
  }
`